<template>
  <div id="app">
    <EnergyStorageCalculator />
  </div>
</template>

<script>
import EnergyStorageCalculator from './components/EnergyStorageCalculator.vue';

export default {
  components: {
    EnergyStorageCalculator,
  },
};
</script>

