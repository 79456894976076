<template>
  <div class="calculator">
    <h2>{{ $t('calculatorTitle') }}</h2>

    <label>{{ $t('cellCapacityLabel') }}
      <input type="number" v-model.number="cellCapacity" placeholder="Pojemność ogniwa">
    </label>

    <label>{{ $t('cellTypeLabel') }}
      <select v-model="cellType">
        <option value="lijon">Li-Ion</option>
        <option value="lifepo">LiFePO₄</option>
        <option value="agm">AGM</option>
      </select>
    </label>

    <label>{{ $t('seriesCountLabel') }}
      <input type="number" v-model.number="seriesCount" placeholder="Ogniwa szeregowo">
    </label>

    <label>{{ $t('parallelCountLabel') }}
      <input type="number" v-model.number="parallelCount" placeholder="Ogniwa równolegle">
    </label>

    <h3>{{ $t('resultsTitle') }}</h3>
    <p>{{ $t('storageCapacity', { value: storageCapacity.toFixed(2) }) }}</p>
    <p>{{ $t('storageCapacityKWh', { value: storageCapacityKWh.toFixed(2) }) }}</p> 
    <p>{{ $t('currentOutput', { value: currentOutput.toFixed(2) }) }}</p>
    <p>{{ $t('voltage', { value: voltage.toFixed(2) }) }}</p>
    <p>{{ $t('minVoltage', { value: minVoltage.toFixed(2) }) }}</p>
    <p>{{ $t('maxVoltage', { value: maxVoltage.toFixed(2) }) }}</p>
    <p>{{ $t('totalCells', { value: totalCells }) }}</p> 
  </div>
</template>

<script>
export default {
  data() {
    return {
      cellCapacity: 5000,  // Domyślna pojemność ogniwa w mAh
      cellType: 'lijon',   // Typ ogniwa
      seriesCount: 1,      // Ilość ogniw szeregowo
      parallelCount: 1,    // Ilość ogniw równolegle
    };
  },
  computed: {
    voltage() {
      return this.seriesCount * this.baseVoltage;
    },
    minVoltage() {
      return this.seriesCount * this.baseMinVoltage;
    },
    maxVoltage() {
      return this.seriesCount * this.baseMaxVoltage;
    },
    storageCapacity() {
      return (this.cellCapacity * this.parallelCount)/1000;
    },
    storageCapacityKWh() {
      return (this.storageCapacity * this.voltage)/1000; // Obliczenie pojemności w kWh
    },
    currentOutput() {
      return this.cellCapacity * this.parallelCount;
    },
    totalCells() {
      return this.seriesCount * this.parallelCount; // Obliczenie liczby ogniw
    },
    baseVoltage() {
      switch (this.cellType) {
        case 'lijon':
          return 3.7;
        case 'lifepo':
          return 3.2;
        case 'agm':
          return 12.85;
        default:
          return 3.7;
      }
    },
    baseMinVoltage() {
      switch (this.cellType) {
        case 'lijon':
          return 3.0;
        case 'lifepo':
          return 2.5;
        case 'agm':
          return 10.5;
        default:
          return 3.0;
      }
    },
    baseMaxVoltage() {
      switch (this.cellType) {
        case 'lijon':
          return 4.2;
        case 'lifepo':
          return 3.65;
        case 'agm':
          return 13.0
        default:
          return 4.2;
      }
    },
  },
};
</script>

<style scoped>
.calculator {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  text-align: left;
}

label {
  display: block;
  margin-bottom: 10px;
}

input, select {
  display: block;
  margin-top: 5px;
  width: 100%;
  padding: 5px;
}

h3 {
  margin-top: 20px;
}
</style>

