import { createApp } from 'vue';
import App from './App.vue';
import { createI18n } from 'vue-i18n';
import pl from './locales/pl.json';
import en from './locales/en.json';

// Wykrywanie języka przeglądarki
const language = navigator.language.startsWith('pl') ? 'pl' : 'en';

const i18n = createI18n({
  locale: language,
  fallbackLocale: 'en',
  messages: { pl, en },
});

const app = createApp(App);
app.use(i18n);
app.mount('#app');

